import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom'; 
const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    const scriptURL = 'https://script.google.com/macros/s/AKfycbyBb-YfJXJdDd0kuR5EQI9OVXYdFxpW6eiWWFiP2HS0VyBplNZFQU-Um3VJ01fu8XKD/exec';
  
    const formData = new FormData();
    formData.append('name', document.getElementById('name').value);
    formData.append('email', document.getElementById('email').value);
    formData.append('mobile', document.getElementById('mobile').value);
    formData.append('message', document.getElementById('message').value);
  
    try {
      const response = await fetch(scriptURL, {
        method: 'POST',
        mode: 'no-cors', // Add this line
        body: formData,
      });
  
      // Since 'no-cors' mode doesn't return a response status, assume success.
      alert('Data submitted successfully!');
      setFormData({ name: '', email: '', mobile: '', message: '' });
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit data');
    }
  };
  

  return (
    <div className="bg-white">
      <div className="page-nav no-margin row">
        <Container>
          <Row>
            <h2 className="text-start mt-5">Contact Us</h2>
            <ul>
              <li><a href="#"><i className="bi bi-house-door"></i> <Link to="/">Home</Link></a></li>
              <li><i className="bi bi-chevron-double-right pe-2"></i> Contact Us</li>
            </ul>
          </Row>
        </Container>
      </div>

      <div className="row no-margin" style={{ marginTop: '0px' }}>
        <iframe
          style={{ width: '100%' ,border: '0'}}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.288708201331!2d72.88171507590505!3d21.14090578391612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0511b97b84003%3A0xef8268689be85d10!2sAatman%20Madhuram%20Arcade%202!5e0!3m2!1sen!2sin!4v1722603993358!5m2!1sen!2sin"
          height="450"
          frameBorder="0"
          allowFullScreen
        />
      </div>

      <div className="row contact-rooo big-padding no-margin">
        <Container>
          <Row>
            <Col sm="7" style={{ padding: '20px' }}>
              <h2 className="fs-4 fw-bold">Contact Form</h2>
              <Form onSubmit={handleFormSubmit}>
                <FormGroup row>
                  <Label for="name" sm="3">Enter Name</Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      id="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Enter Name"
                      className="form-control input-sm"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="email" sm="3">Email Address</Label>
                  <Col sm="8">
                    <Input
                      type="email"
                      id="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Enter Email Address"
                      className="form-control input-sm"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="mobile" sm="3">Mobile Number</Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      id="mobile"
                      value={formData.mobile}
                      onChange={handleInputChange}
                      placeholder="Enter Mobile Number"
                      className="form-control input-sm"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="message" sm="3">Enter Message</Label>
                  <Col sm="8">
                    <Input
                      type="textarea"
                      id="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      rows="5"
                      placeholder="Enter Your Message"
                      className="form-control input-sm"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm="3"></Col>
                  <Col sm="8">
                    <Button color="danger" className="fs-5 btn-sm" type="submit">Send Message</Button>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col sm="5">
              <div style={{ margin: '50px' }} className="serv">
                <h2 className="fs-4 fw-bold" style={{ marginTop: '10px' }}>Address</h2>
                <p className="fs-5">
                  Office No 14, 5th floor <br />
                  Madhuram Arcade-2 <br />
                  Near Madhuram Circle <br />
                  Dindoli, Surat, 394210 <br />
                  Phone: +91 9558476369 <br />
                  Email: avataratechnobiz@gmail.com <br />
                  Website: https://avataratechnobiz.com/ <br />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ContactPage;

