import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const HeadAndScripts = () => {
  useEffect(() => {
    const scriptOrder = [
      "/assets/js/jquery-3.2.1.min.js",
    //   "/assets/js/popper.min.js",
      "/assets/js/bootstrap.bundle.min.js",
      "/assets/plugins/scroll-fixed/jquery-scrolltofixed-min.js",
      "/assets/plugins/testimonial/js/owl.carousel.min.js",
      "/assets/js/typewrite.min.js",
      "/assets/js/script.js"
    ];

    scriptOrder.forEach(src => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    });

    return () => {
      scriptOrder.forEach(src => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <title>Avatara TechnoBiz</title>
      <link rel="icon" type="image/x-icon" href="/assets/images/title_logo.jpg" />
      <link rel="shortcut icon" href="/assets/images/fav.png" type="image/x-icon" />
      <link rel="shortcut icon" href="/assets/images/fav.jpg" />
      <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/fontawsom-all.min.css" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css" />
      <link rel="stylesheet" href="/assets/plugins/testimonial/css/owl.carousel.min.css" />
      <link rel="stylesheet" href="/assets/plugins/testimonial/css/owl.theme.min.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
    </Helmet>
  );
};

export default HeadAndScripts;
