import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';

const HomePage = () => {
  

  const arr = [
    { image: "/assets/images/servicesImg/python-logo.png", title: "Python", description: "WordPress is providing a free publishing software and content management system that allows developers to create a wide array of plug-ins, themes and widgets." },
    { image: "/assets/images/servicesImg/image.png", title: "React", description: "React Native is a system renderer for React, just like React DOM on The Web. React Native excellence native components instead of web elements like React as building blocks." },
    { image: "/assets/images/servicesImg/node.png", title: "Node", description: "PrestaShop is an efficient, user- friendly and innovative eCommerce solution with all the features you need to create an online store and grow your business." },
    { image: "/assets/images/servicesImg/nextjs-icon.png", title: "Next.Js", description: "Magneto is an eCommerce platform built on open source technology that helps merchants and customers with a esly online shopping cart system is popular eCommerce platforms." },
    { image: "/assets/images/servicesImg/mern-stack.png", title: "MERN", description: "MERN excel in scalability using a single programming language for both front-end and back-end components. In contrast, Full-Stack may need help to handle a rapid influx of users, potentially impeding its performance." },
    { image: "/assets/images/servicesImg/angular.png", title: "Angular", description: "AngularJS gives developers an opportunities to write client side information using JavaScript in a clean Model View Controller (MVC) way." },
    { image: "/assets/images/servicesImg/php.png", title: "PHP", description: "PHP, or Hypertext Preprocessor, is a scripting language used to create dynamic web pages and applications. It's a general-purpose language that can be embedded into HTML and is widely used for web development" },
    { image: "/assets/images/servicesImg/wordpress.png", title: "WordPress", description: "WordPress is providing a free publishing software and content management system that allows developers to create a wide array of plug-ins, themes and widgets." },
  ];
  return (
    <div>
      {/* Carousel */}
      <div id="carouselExampleIndicators" className="carousel slide mt-2"  data-bs-ride="true">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner ">
          {/* Slide 1 */}
          <div className="carousel-item active">
            <img src="assets/images/slider/s1.jpg" className="d-block w-100" alt="Slide 1" />
            <div className="carousel-caption d-md-block">
              <div className="row">
                <div className="col-lg-6 align-self-center text-start animate__animated animate__backInUp">
                  <h1 className="fs-12 fw-bolder text-start">For All Your <br /><span className="text-primary">Digital Needs</span></h1>
                  <p className="text-dark d-none d-md-block text-start">There are many different varieties of digital services available… Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac laoreet lacus. Morbi auctor quam tempus massa vestibulum aliquet eu vitae ligula.</p>
                  <div className="d-inline-block pt-5 text-start d-none d-lg-block">
                    <button className="btn btn-primary shadow fs-5 fw-bolder px-5 py-2">Buy Now</button>
                    <button className="btn btn-outline-primary ms-4 shadow fs-5 fw-bolder px-5 py-2">Buy Now</button>
                  </div>
                </div>
                <div className="col-lg-6 animate__animated animate__backInDown align-self-center d-none d-lg-block">
                  <img className="w-100" src="assets/images/h1.png" alt="Illustration" />
                </div>
              </div>
            </div>
          </div>
          {/* Slide 2 */}
          <div className="carousel-item">
            <img src="assets/images/slider/s2.jpg" className="d-block w-100" alt="Slide 2" />
            <div className="carousel-caption d-md-block">
              <div className="row">
                <div className="col-lg-6 align-self-center animate__animated animate__backInLeft">
                  <h1 className="fs-12 fw-bolder text-start text-light">For All Your <br /><span className="text-dark">Digital Needs</span></h1>
                  <p className="d-none d-md-block text-white text-start">There are many different varieties of flower honey available… Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac laoreet lacus. Morbi auctor quam tempus massa vestibulum aliquet eu vitae ligula.</p>
                  <div className="d-inline-block pt-5 text-start d-none d-lg-block">
                    <button className="btn btn-light shadow fs-5 fw-bolder px-5 py-2">Buy Now</button>
                    <button className="btn btn-outline-light ms-4 shadow fs-5 fw-bolder px-5 py-2">Buy Now</button>
                  </div>
                </div>
                <div className="col-lg-6 animate__animated animate__backInRight align-self-center d-none d-lg-block">
                  <img className="w-100" src="assets/images/h2.png" alt="Illustration" />
                </div>
              </div>
            </div>
          </div>
          {/* Slide 3 */}
          <div className="carousel-item">
            <img src="assets/images/slider/s3.jpg" className="d-block w-100" alt="Slide 3" />
            <div className="carousel-caption d-md-block">
              <div className="row">
                <div className="col-lg-6 align-self-center animate__animated animate__backInDown">
                  <h1 className="fs-12 fw-bolder text-start">For All Your <br /><span className="text-white">Digital Needs</span></h1>
                  <p className="d-none d-md-block text-dark text-start">There are many different varieties of flower honey available… Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac laoreet lacus. Morbi auctor quam tempus massa vestibulum aliquet eu vitae ligula.</p>
                  <div className="d-inline-block pt-5 text-start d-none d-lg-block">
                    <button className="btn btn-light shadow fs-5 fw-bolder px-5 py-2">Buy Now</button>
                    <button className="btn btn-outline-light ms-4 shadow fs-5 fw-bolder px-5 py-2">Buy Now</button>
                  </div>
                </div>
                <div className="col-lg-6 align-self-center d-none d-lg-block animate__animated animate__backInUp">
                  <img className="w-100" src="assets/images/h3.png" alt="Illustration" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"><i className="bi fs-4 text-dark bi-chevron-left"></i></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"><i className="bi fs-4 text-dark bi-chevron-right"></i></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* What We Do */}
      <div id="testimonial" className="service bg-honey big-padding bg-gray container-fluid px-4 py-5">
        <div className="container-xl">
          <div className="section-title row mb-3">
            <h2 className="fw-bolder">What We Do</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ipsum sit nibh amet egestas tellus.</p>
          </div>
          <div className="row">
            {['AI Development', 'Mobile App Development', 'Web App Development', 'Cloud Services', 'Desktop Application', 'iPhone App Development'].map((service, index) => (
              <div className="col-xl-4 col-md-6 mb-4" key={index}>
                <div className="bg-white shadow-md">
                  <img height={250} width={500} src={`assets/images/case/0${(index + 1)}.jpg`} alt={service} />
                  <div className="p-3 text-center">
                    <h4 className="fs-6 fw-bold">{service}</h4>
                    <p>Avatara TechnoBiz</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Key Features */}
      <div className="key-feature container-fluid">
        <div className="container">
          <div className="session-title row">
            <h2>Key Features</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fringilla vel nisl a dictum. Donec ut est arcu. Donec hendrerit velit</p>
          </div>
          <div className="row">
            {[
              { icon: 'far fa-file-word', title: 'Programming Experts', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fringilla vel nisl a dictum. Donec ut est arcu. Donec hendrerit velit' },
              { icon: 'far fa-clock', title: 'Timely Delivery', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fringilla vel nisl a dictum. Donec ut est arcu. Donec hendrerit velit' },
              { icon: 'fas fa-object-group', title: 'Quality Design', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fringilla vel nisl a dictum. Donec ut est arcu. Donec hendrerit velit' },
              { icon: 'fas fa-phone-volume', title: '24 x 7 Support', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fringilla vel nisl a dictum. Donec ut est arcu. Donec hendrerit velit' }
            ].map((feature, index) => (
              <div className="col-md-3 key-div" key={index}>
                <div className="key-cover">
                  <i className={feature.icon}></i>
                  <h4>{feature.title}</h4>
                  <p align="left">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* --------services-section--------- */}
      <div id="testimonial" className="service bg-honey big-padding bg-white container-fluid px-4 py-5">
        <div className="container-xl">
          <div className="service-section row mb-3">
            <h3>Special Services to Transform Your Business</h3>
          </div>
          <div className="row">
            {arr.map(({ image, title, description }, index) => (
              <div className="col-xl-3 col-md-4 mb-4  " key={index}>
                <div className=" text-gray fw-bolder p-1 img-box">
                  <img height={150} width={80} src={image} alt={title} className=" img-fluid mx-auto d-block  rounded-circle mt-2 " />
                  <div className="p-3 text-center">
                    <h4 className="fs-6 fw-bold text-dark">{title}</h4>
                    <p className='fs fw-bold text-gray'>{description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <h5 className='bttn mx-auto mt-3' ><Link to={"/services"}>MORE ABOUT SPECIAL SERVICES</Link></h5>
      </div>

      {/* --------services-section-end--------- */}
    </div>
  );
};

export default HomePage;
