import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import { FaEnvelope, FaPhone, FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa'; 

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed-header">
      <div className="hed-top text-white d-none d-sm-block border-bottom">
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-8 d-none d-lg-block">
              <ul className="text-white leftlist ld fs-8">
                <li className="float-start">
                  <FaEnvelope /> avataratechnobiz@gmail.com
                </li>
                <li className="float-start">
                  <FaPhone /> +91 9558476369
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul id='icon-menu' className="text-white pt-1 float-end">
                <li id='icon-list' className="float-start fs-8 p-3 py-2"><FaFacebook /></li>
                <li id='icon-list' className="float-start fs-8 p-3 py-2"><FaTwitter /></li>
                <li id='icon-list' className="float-start fs-8 p-3 py-2"><FaInstagram /></li>
                <li id='icon-list' className="float-start fs-8 p-3 py-2"><FaLinkedin /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div id="menu-jk" className="nav-part bg-white w-100">
          <div className="container-xl">
            <div className="row bg-white navcol p-2">
              <div className="col-lg-4">
                <a href="/">
                  <img className="w-50" src="assets/images/final_logo.png" alt="Logo" />
                </a>
                <button
                  className="navbar-toggler float-end mt-3"
                  type="button"
                  aria-controls="menu"
                  aria-expanded={isMenuOpen ? "true" : "false"}
                  aria-label="Toggle navigation"
                  onClick={toggleMenu}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div
                id="menu"
                className={`collapse navbar-collapse col-lg-8 text-center ${isMenuOpen ? "d-block" : "d-none"}`}
              >
                <ul className="nav-menu navbar-nav fw-bold float-end nacul fs-7">
                  <li className="nav-list text-center float-start p-3 px-4">
                    <Link className="nav-link" to="/">Home</Link>
                  </li>
                  <li className="nav-list float-start p-3 px-4 dropdown">
                    <Link className="nav-link" to="/whatwedo">What we do</Link>
                    <ul className="dropdown-menu mt-2" aria-labelledby="dropdownMenuButton1">
                      <li><Link className="dropdown-item mx-auto p-1" to="/whatwedo">Ai Development</Link></li>
                      <li><Link className="dropdown-item p-1" to="/whatwedo">Mobile App Development</Link></li>
                      <li><Link className="dropdown-item p-1" to="/whatwedo">Web App Development</Link></li>
                      <li><Link className="dropdown-item p-1" to="/whatwedo">Cloud Services</Link></li>
                      <li><Link className="dropdown-item p-1" to="/whatwedo">Desktop Application</Link></li>
                      <li><Link className="dropdown-item p-1" to="/whatwedo">iPhone App Development</Link></li>
                    </ul>
                  </li>
                  <li className="nav-list float-start p-3 px-4">
                    <Link className="nav-link" to="/services">Services</Link> 
                  </li>
                  <li className="nav-list float-start p-3 px-4">
                    <Link className="nav-link" to="/about">About Us</Link>
                  </li>
                  <li className="nav-list float-start p-3 px-4">
                    <Link  className="nav-link" to="/career">Career</Link>
                  </li>
                  <li className="nav-list float-start p-3 px-4">
                    <Link className="nav-link" to="/contact">Contact Us</Link>
                  </li>
                  <li className="nav-list float-start p-3 px-4">
                    <Link className="nav-link" to="/form"></Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
