import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

const services = {
    aiDevelopment: [
        {
            title: "Machine Learning",
            description: "Our Machine Learning services are designed to empower your business with intelligent algorithms that can analyze data, make predictions, and automate tasks. We harness the power of data to create tailored ML solutions that enhance your operations and decision-making processes.",
            imgPath: "assets/images/whatwedo/machine-learning-icon.svg",
            altText: "Machine Learning",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=ai-leads&utm_medium=what-we-do&utm_source=website&utm_term=machine-learning"
        },
        {
            title: "Deep Learning",
            description: "Dive into the realm of Deep Learning with our expertise. We develop neural networks that can understand and process complex data, making them ideal for applications like image and speech recognition, recommendation systems, and more.",
            imgPath: "assets/images/whatwedo/deep-learning-icon.svg",
            altText: "Deep Learning",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=ai-leads&utm_medium=what-we-do&utm_source=website&utm_term=deep-learning"
        },
        {
            title: "Computer Vision",
            description: "Transform your business with Computer Vision technology. Our solutions enable machines to interpret and understand visual information, opening up possibilities for applications in image analysis, object detection, and augmented reality.",
            imgPath: "assets/images/whatwedo/computer-vision-icon.svg",
            altText: "Computer Vision",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=ai-leads&utm_medium=what-we-do&utm_source=website&utm_term=computer-vision"
        },
        {
            title: "Natural Language Processing",
            description: "Communicate with your data through our Natural Language Processing services. We enable machines to comprehend and generate human language, making it possible to analyze and extract valuable insights from text data.",
            imgPath: "assets/images/whatwedo/natural-language-processing-icon.svg",
            altText: "Natural Language Processing",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=ai-leads&utm_medium=what-we-do&utm_source=website&utm_term=natural-language-processing"
        },
        {
            title: "Generative AI",
            description: "We specialize in creating intelligent algorithms that can generate new content, whether it's text, images, or other forms of data. Our Generative AI solutions are designed to help your business create, modify, or enhance content, opening up possibilities for applications in content generation, creative design, and more.",
            imgPath: "assets/images/whatwedo/generative-ai.svg",
            altText: "Generative AI",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=ai-leads&utm_medium=what-we-do&utm_source=website&utm_term=generative-ai"
        },
        {
            title: "Prompt Engineering",
            description: "Elevate your AI development with our Prompt Engineering expertise. Our services focus on fine-tuning language models and algorithms to generate specific responses and outputs based on customized prompts. We empower AI applications to deliver the right information and engage with users more naturally.",
            imgPath: "assets/images/whatwedo/prompt-engineering.svg",
            altText: "Prompt Engineering",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=ai-leads&utm_medium=what-we-do&utm_source=website&utm_term=prompt-engineering"
        }
    ],
    appDevelopment: [
        {
            title: "Android Native App Development",
            description: "Build highly performant and scalable mobile apps with a focus on user experience, cross-platform development, and cutting-edge mobile technologies.",
            imgPath: "assets/images/whatwedo/Androide-app-icon.svg",
            altText: "Mobile App Development",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=app-leads&utm_medium=what-we-do&utm_source=website&utm_term=mobile-app-development"
        },

        {
            title: "iOS Native App Development",
            description: "Specialising in iOS Native App Development, we harness the power of Swift UI and Objective-C to construct high-performance mobile applications tailored to iOS devices.",
            imgPath: "assets/images/whatwedo/ios-app.svg",
            altText: "Mobile App Development",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=app-leads&utm_medium=what-we-do&utm_source=website&utm_term=mobile-app-development"
        },
        {
            title: "React Native",
            description: "Our expertise in React Native App Development shines through in the creation of customised mobile applications that seamlessly operate across multiple platforms.",
            imgPath: "assets/images/whatwedo/react-native.svg",
            altText: "Mobile App Development",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=app-leads&utm_medium=what-we-do&utm_source=website&utm_term=mobile-app-development"
        },

        {
            title: "Flutter",
            description: "With our Flutter App Development services, we bring forth feature-rich and interactive mobile applications that smoothly run on both Android and iOS platforms.",
            imgPath: "assets/images/whatwedo/flutter.svg",
            altText: "Mobile App Development",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=app-leads&utm_medium=what-we-do&utm_source=website&utm_term=mobile-app-development"
        },
    ],
    webDevelopment: [
        {
            title: "Frontend Development",
            description: "Our mastery of frontend development, utilizing HTML, CSS, and JS, results in visually captivating UI/UX experiences. These designs seamlessly integrate with backends, APIs, and third-party services, all while ensuring cross-browser compatibility.",
            imgPath: "assets/images/whatwedo/frontend.svg",
            altText: "Web Development",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=web-leads&utm_medium=what-we-do&utm_source=website&utm_term=web-development"
        },
        {
            title: "Backend Development",
            description: "Offering specialized backend development, we harness the capabilities of Python, PHP, Java, and JS to implement business logic, develop and integrate APIs, manage subscriptions and billing, and process real-time data. This forms the backbone of your application's functionality.",
            imgPath: "assets/images/whatwedo/backend.svg",
            altText: "Web Development",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=web-leads&utm_medium=what-we-do&utm_source=website&utm_term=web-development"
        },{
            title: "Database",
            description: "Our expertise extends to crafting and maintaining efficient relational and non-relational databases. These environments provide reliable and scalable data storage solutions, ensuring optimal performance and data integrity.",
            imgPath: "assets/images/whatwedo/database.svg",
            altText: "Web Development",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=web-leads&utm_medium=what-we-do&utm_source=website&utm_term=web-development"
        }
    ],
    CloudServices: [
        {
            title: "Cloud Integration",
            description: "Through our Cloud Integration services, we seamlessly connect your existing systems and applications with cloud platforms. This enhances efficiency, facilitates data accessibility, and fosters seamless communication between cloud-based and on-premises systems.",
            imgPath: "assets/images/whatwedo/cloud.svg",
            altText: "SEO Optimization",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=marketing-leads&utm_medium=what-we-do&utm_source=website&utm_term=seo-optimization"
        },
        {
            title: "Cloud Migration",
            description: "Our Cloud Migration services guide businesses through a smooth transition of their IT infrastructure and applications to the cloud. This shift brings improved scalability and cost efficiency, optimizing your technological landscape.",
            imgPath: "assets/images/whatwedo/cloud-migration.svg",
            altText: "SEO Optimization",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=marketing-leads&utm_medium=what-we-do&utm_source=website&utm_term=seo-optimization"
        },
        {
            title: "Cloud Server Solutions",
            description: "Experience scalable and dependable hosting services with our Cloud Server Solutions. Tailored to meet your business needs, these solutions ensure uninterrupted data access and application performance.",
            imgPath: "assets/images/whatwedo/cloud-server-solutions.svg",
            altText: "SEO Optimization",
            linkText: "Get In Touch",
            linkHref: "/contact-us?utm_campaign=marketing-leads&utm_medium=what-we-do&utm_source=website&utm_term=seo-optimization"
        }
    ]
};

const WhatWeDoPage = () => {
    const [activeTab, setActiveTab] = useState('aiDevelopment');

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <>
            <div className="page-nav no-margin row">
                <Container>
                    <Row>
                        <h2 className="text-start mt-5">What We Do</h2>
                        <ul>
                            <li><a href="#"><i className="bi bi-house-door"></i>  <Link to="/">Home</Link></a></li>
                            <li><i className="bi bi-chevron-double-right pe-2 "></i> What We Do</li>
                        </ul>
                    </Row>
                </Container>
            </div>

            <Container className="my-5">
                <h1 className="text-center my-4">What We Do</h1>
                <p className="text-center mb-5">
                    We seamlessly blend our technical acumen with expert marketing strategies to provide your business with an unparalleled competitive advantage. Our range of services is designed to cater to specific needs and propel your business to new heights.
                </p>

                {/* Tab Navigation */}
                <Nav tabs className="justify-content-center flex-column flex-md-row bb" >
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'aiDevelopment', 'bg-orange': activeTab === 'aiDevelopment' })}
                            onClick={() => toggleTab('aiDevelopment')}
                            style={{ cursor: 'pointer', color: activeTab === 'aiDevelopment' ? 'orange' : 'black' }}
                        >
                            AI Development
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'appDevelopment', 'bg-orange': activeTab === 'appDevelopment' })}
                            onClick={() => toggleTab('appDevelopment')}
                            style={{ cursor: 'pointer', color: activeTab === 'appDevelopment' ? 'orange' : 'black' }}
                        >
                            Mobile App Development
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'webDevelopment', 'bg-orange': activeTab === 'webDevelopment' })}
                            onClick={() => toggleTab('webDevelopment')}
                            style={{ cursor: 'pointer', color: activeTab === 'webDevelopment' ? 'orange' : 'black' }}
                        >
                            Web App Development
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'CloudServices', 'bg-orange': activeTab === 'CloudServices' })}
                            onClick={() => toggleTab('CloudServices')}
                            style={{ cursor: 'pointer', color: activeTab === 'CloudServices' ? 'orange' : 'black' }}
                        >
                            Cloud Services
                        </NavLink>
                    </NavItem>
                </Nav>

                {/* Tab Content */}
                <TabContent activeTab={activeTab}>
                    {Object.keys(services).map((tabKey) => (
                        <TabPane tabId={tabKey} key={tabKey}>
                            <Row className='mt-5'>
                                {services[tabKey].map((service, index) => (
                                    <Col lg="6" md="6" sm="12" key={index} className="mb-4">
                                        <div className="b-values-item p-4 bg-white shadow-sm rounded h-100">
                                            <div className="b-values-icon mb-3">
                                                <img src={service.imgPath} alt={service.altText} loading="lazy" width="60" height="60" />
                                            </div>
                                            <h3 className="fs-5 fw-bold">{service.title}</h3>
                                            <p>{service.description}</p>
                                            <a href={service.linkHref} className="btn btn-primary mt-3">{service.linkText}</a>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </TabPane>
                    ))}
                </TabContent>
            </Container>
        </>
    );
};

export default WhatWeDoPage;
