import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';


const Career = () => {
  const jobOpenings = [
    {
      title: 'Python Developer',
      description: 'We are looking for a Python Developer with experience in building high-performing, scalable, enterprise-grade applications.',
      requirements: [
        'Strong understanding of Python and its libraries',
        'Experience with Django or Flask',
        'Knowledge of front-end technologies like JavaScript, HTML5, and CSS3',
        'Familiarity with databases (MySQL, PostgreSQL, MongoDB)',
        'Excellent problem-solving skills',
      ],
    },
    {
      title: 'PHP Developer',
      description: 'We are seeking a PHP Developer responsible for managing back-end services and the interchange of data between the server and the users.',
      requirements: [
        'Proficient in PHP, HTML5, JavaScript, and CSS3',
        'Experience with PHP frameworks like Laravel or CodeIgniter',
        'Knowledge of object-oriented PHP programming',
        'Understanding of MVC design patterns',
        'Strong understanding of database technologies (MySQL, PostgreSQL)',
      ],
    },
    {
      title: 'React.js Developer',
      description: 'We are looking for a skilled React.js Developer to join our front-end development team.',
      requirements: [
        'Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model',
        'Thorough understanding of React.js and its core principles',
        'Experience with popular React.js workflows (such as Flux or Redux)',
        'Familiarity with RESTful APIs',
        'Knowledge of modern authorization mechanisms, such as JSON Web Token',
      ],
    },
    {
      title: 'Node.js Developer',
      description: 'We are seeking a Node.js Developer to join our back-end development team.',
      requirements: [
        'Strong proficiency with JavaScript or TypeScript',
        'Knowledge of Node.js and frameworks such as Express',
        'Experience with SQL or NoSQL databases',
        'Understanding of server-side templating languages',
        'Familiarity with front-end technologies, such as HTML5 and CSS3',
      ],
    },
    {
      title: 'React Native Developer',
      description: 'We are looking for a React Native Developer to join our mobile app development team.',
      requirements: [
        'Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model',
        'Thorough understanding of React Native and its core principles',
        'Experience with popular React Native workflows (such as Redux)',
        'Familiarity with RESTful APIs',
        'Experience with common mobile development tools and libraries',
      ],
    },
  ];

  return (
    <div>
      <div className="page-nav no-margin row">
        <Container>
          <Row>
            <h2 className="text-start mt-5">Career</h2>
            <ul>
              <li><a href="#"><i className="bi bi-house-door"></i>  <Link to="/">Home</Link></a></li>
              <li><i className="bi bi-chevron-double-right pe-2"></i> Career</li>
            </ul>
          </Row>
        </Container>
      </div>
      
      <div className="container-fluid py-5">
        <div className="container">
          <h2 className="text-center mb-5">Career Opportunities</h2>
          <div className="row">
            {jobOpenings.map((job, index) => (
              <div className="col-lg-4 col-md-6 mb-4" key={index}>
                <div className="job-card p-4 bg-white shadow-sm rounded">
                  <h3 className="fs-5 fw-bold">{job.title}</h3>
                  <p>{job.description}</p>
                  <h4 className="fs-6 fw-bold">Requirements:</h4>
                  <ul>
                    {job.requirements.map((req, idx) => (
                      <li key={idx}>{req}</li>
                    ))}
                  </ul>
                  <Link to={"/form"}><button className="btn btn-primary mt-3 w-100">Apply Now</button></Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
